import {
    COLLEAGUES,
    CONVERSATION,
    HANDSHAKE,
    HEALTHCARE,
    HEART,
    HIRED,
    INDIVIDUAL_CARE,
    MOTIVATED,
    NEWS
} from "components/ui";

import { MainMenuItemType } from "./MainNavigation";

export const MainMenuItems: Array<MainMenuItemType> = [
    {
        href: "/zorgprofessionals",
        id: "zorgprofessionals",
        submenu: {
            primary: {
                items: [
                    // {
                    //     href: "/zorgprofessionals",
                    //     icon: ARROW_RIGHT,
                    //     title: "Zorgprofessionals",
                    //     type: "heading"
                    // },
                    {
                        description:
                            "Alle beschikbare vacatures & ZZP opdrachten",
                        href: "/vacatures",
                        icon: HIRED,
                        title: "Vacatures & opdrachten",
                        type: "card"
                    },
                    {
                        description:
                            "Lees ervaringen van mede zorgprofessionals / ZZP’ers",
                        href: "/zorgprofessionals/ervaringen",
                        icon: CONVERSATION,
                        title: "Ervaringen",
                        type: "card"
                    },
                    {
                        description:
                            "Hoe wij jou als zorgprofessional centraal zetten",
                        href: "/zorgprofessionals/voordelen",
                        icon: MOTIVATED,
                        title: "Werken bij Lead Healthcare",
                        type: "card"
                    }
                ],
                title: "Alles voor Zorgprofessionals"
            },
            secondary: {
                items: [
                    // {
                    //     href: "/zorgprofessionals/student",
                    //     title: "Student",
                    //     type: "link"
                    // },
                    {
                        href: "/zorgprofessionals/verpleegkundige",
                        title: "Verpleegkundige",
                        type: "link"
                    },
                    {
                        href: "/zorgprofessionals/basisarts",
                        title: "Basisarts",
                        type: "link"
                    },
                    // {
                    //     href: "/zorgprofessionals/farmakundige",
                    //     title: "Farmakundige",
                    //     type: "link"
                    // },
                    // {
                    //     href: "/zorgprofessionals/farmaceutisch-consulent",
                    //     title: "Farmaceutisch consulent",
                    //     type: "link"
                    // },
                    // {
                    //     href: "/zorgprofessionals/doktersassistent",
                    //     title: "Doktersassistent",
                    //     type: "link"
                    // },
                    {
                        href: "/zorgprofessionals/apothekersassistent",
                        title: "Apothekersassistent",
                        type: "link"
                    },
                    // {
                    //     href: "/zorgprofessionals/teststraatmedewerker",
                    //     title: "Teststraatmedewerker",
                    //     type: "link"
                    // },
                    {
                        href: "/zorgprofessionals/apotheker",
                        title: "Apotheker",
                        type: "link"
                    },
                    {
                        href: "/zzper",
                        title: "ZZP’er in de zorg",
                        type: "link"
                    },
                    {
                        href: "/zorgprofessionals/doktersassistent",
                        title: "Doktersassistent",
                        type: "link"
                    },
                    {
                        href: "/specialist-ouderengeneeskunde",
                        title: "Specialist Ouderengeneeskunde",
                        type: "link"
                    }
                ],
                title: "Ik ben een..."
            }
        },
        title: "Zorgprofessionals"
    },
    {
        href: "/opdrachtgevers",
        id: "clients",
        submenu: {
            primary: {
                items: [
                    // {
                    //     href: "/opdrachtgevers",
                    //     icon: ARROW_RIGHT,
                    //     title: "Opdrachtgevers",
                    //     type: "heading"
                    // },
                    {
                        description: "De voordelen van een samenwerking",
                        href: "/opdrachtgevers/voordelen",
                        icon: MOTIVATED,
                        title: "Werken met Lead Healthcare",
                        type: "card"
                    },
                    {
                        description: "Onze mooie samenwerkingen",
                        href: "/zorgprofessionals/ervaringen",
                        icon: HEALTHCARE,
                        title: "Ervaringen",
                        type: "card"
                    },
                    {
                        description: "Samenwerken met happy professionals",
                        href: "/opdrachtgevers/aansluiten",
                        icon: HANDSHAKE,
                        title: "Neem contact op",
                        type: "inverted-card"
                    }
                ],
                title: "Alles voor Zorginstellingen"
            },
            secondary: {
                items: [
                    {
                        href: "/opdrachtgevers/vvt",
                        title: "VVT",
                        type: "link"
                    },
                    {
                        href: "/opdrachtgevers/ziekenhuis",
                        title: "Ziekenhuis",
                        type: "link"
                    },
                    {
                        href: "/opdrachtgevers/apotheek",
                        title: "Apotheek",
                        type: "link"
                    },
                    {
                        href: "/opdrachtgevers/it-consultancy",
                        title: "IT Consultancy",
                        type: "link"
                    },
                    {
                        href: "/opdrachtgevers/gehandicaptenzorg",
                        title: "Gehandicaptenzorg",
                        type: "link"
                    },
                    {
                        href: "/opdrachtgevers/geestelijke-gezondheidszorg",
                        title: "Geestelijke Gezondheidszorg",
                        type: "link"
                    },
                    {
                        href: "/opdrachtgevers/artsen",
                        title: "Artsen",
                        type: "link"
                    },
                    {
                        href: "/opdrachtgevers/huisartsenzorg",
                        title: "Huisartsenzorg",
                        type: "link"
                    }
                    // {
                    //     href: "/opdrachtgevers/overheid",
                    //     title: "Overheid",
                    //     type: "link"
                    // },
                    // {
                    //     href: "/opdrachtgevers/laboratoria",
                    //     title: "Laboratoria",
                    //     type: "link"
                    // },
                    // {
                    //     href: "/opdrachtgevers/testbedrijven",
                    //     title: "Testbedrijven",
                    //     type: "link"
                    // },
                    // {
                    //     href: "/opdrachtgevers/events",
                    //     title: "Events",
                    //     type: "link"
                    // },
                    // {
                    //     href: "/opdrachtgevers/bedrijven",
                    //     title: "Bedrijven",
                    //     type: "link"
                    // }
                ],
                title: "Meer info voor..."
            }
        },
        title: "Zorginstellingen"
    },
    // {
    //     href: "/diensten",
    //     id: "services",
    //     submenu: {
    //         primary: {
    //             items: [
    //                 {
    //                     href: "/diensten",
    //                     title: "Wat wij doen",
    //                     type: "heading"
    //                 },
    //                 {
    //                     description:
    //                         "Wij zetten zorgprofessionals in die perfect passen op onze opdrachten bij zorginstellingen.",
    //                     href: "/diensten/zorgprofessionals",
    //                     icon: DOCTOR,
    //                     title: "zorgprofessionals",
    //                     type: "card"
    //                 },
    //                 {
    //                     description:
    //                         "Onze zorgprofessionals leveren een waardevolle bijdrage aan mooie en innovatieve projecten.",
    //                     href: "/diensten",
    //                     icon: HEALTHCARE,
    //                     title: "Projecten",
    //                     type: "card"
    //                 }
    //             ],
    //             title: "Onze diensten"
    //         }
    //     },
    //     title: "Wat wij doen"
    // },
    {
        href: "/over-ons",
        id: "about-us",
        submenu: {
            primary: {
                items: [
                    // {
                    //     href: "/over-ons",
                    //     title: "Over ons",
                    //     type: "heading"
                    // },
                    {
                        description: "Waar wij voor gaan",
                        href: "/over-ons",
                        icon: INDIVIDUAL_CARE,
                        title: "Ons verhaal",
                        type: "card"
                    },
                    {
                        description: "Werken op het hoofdkantoor",
                        href: "/zorgprofessionals/vacatures?departments=Kantoor",
                        icon: COLLEAGUES,
                        title: "Vacatures kantoor",
                        type: "card"
                    },
                    {
                        description: "Ontdek onze cultuur",
                        href: "/over-ons/lead-healthcare-hq",
                        icon: HEART,
                        title: "Work Happy",
                        type: "card"
                    },
                    {
                        description: "Blijf op de hoogte van Lead Healthcare",
                        href: "/nieuws",
                        icon: NEWS,
                        title: "Nieuws & Blogs",
                        type: "card"
                    }
                ],
                title: "Alles over Lead Healthcare"
            },
            secondary: {
                items: [
                    {
                        description: "Apotheker",
                        href: "/zorgprofessionals/ervaringen/frits",
                        icon: "/images/avatars/frits.jpg",
                        title: "Frits",
                        type: "story"
                    },
                    {
                        description: "Verpleegkundige",
                        href: "/zorgprofessionals/ervaringen/britt",
                        icon: "/images/avatars/britt.jpg",
                        title: "Britt",
                        type: "story"
                    },
                    {
                        description: "Apothekerassistent",
                        href: "/zorgprofessionals/ervaringen/diana",
                        icon: "/images/avatars/diana.jpg",
                        title: "Diana",
                        type: "story"
                    },
                    {
                        description: "Basisarts",
                        href: "/zorgprofessionals/ervaringen/reinier",
                        icon: "/images/avatars/reinier.jpg",
                        title: "Reinier",
                        type: "story"
                    }
                ],
                title: "Ervaringen van onze collega's"
            }
        },
        title: "Over ons"
    }
];
